import React, { useContext } from 'react'
// import style from './Studies.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import second1 from '../../assets/images/WhatsApp Image 2025-01-27 at 12.59.16 PM.webp'
import second2 from '../../assets/images/ART-كشف التكهفات.webp'
import fourth1 from '../../assets/images/Secant-piles.webp'
import fourth2 from '../../assets/images/3.webp'///
import fifth1 from '../../assets/images/2.webp'
import fifth2 from '../../assets/images/cd2e0e1e52cb47d62b3b4decdcb7ea1f.webp'
import fifth3 from '../../assets/images/Feat-Retaining-Walls.webp'
import sixth1 from '../../assets/images/101.webp'
import sixth2 from '../../assets/images/applsci-11-01343-g001-550.webp'
import seventh1 from '../../assets/images/116561cf68433dee167026a6bd0fc405.webp'
import seventh2 from '../../assets/images/Pile-foundations.webp'
import seventh3 from '../../assets/images/1653033675576.webp'
import eightth1 from '../../assets/images/challenging_gw_conditions.webp'
import eightth2 from '../../assets/images/1434193904p.webp'
import eightth3 from '../../assets/images/42452_2019_1484_Fig1_HTML.webp'
import ninth1 from '../../assets/images/GPR2.webp'
import ninth2 from '../../assets/images/GPR4.webp'
import ninth3 from '../../assets/images/GPR6.webp'
import ninth4 from '../../assets/images/مسح طبقات الأرض بجهاز الجيورادار.webp'
import ninth5 from '../../assets/images/مسح وكشف الخدمات تحت الأرضية بجهاز الجيورادار GPR.webp'
import ninth6 from '../../assets/images/4.webp'
import tenth1 from '../../assets/images/المقاومة الكهربائية.webp'
import tenth2 from '../../assets/images/فقرة الدرسات الفنية 2.webp'
import tenth3 from '../../assets/images/فقرة الدرسات الفنية الخاصة.webp'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Studies() {
  
    const { t } = useTranslation()
    let {language}= useContext(LangContext)

  return <>
  <div dir={language==='en'? '':'rtl'}>
    <div className="header-img py-5">
      <div className='w-90 mx-auto d-flex h-100 flex-column align-items-center justify-content-center'>
        <p data-aos="zoom-in" className='position-relative line fs-2 fw-bold text-black d-flex align-items-center text-center justify-content-center pb-3 mt-3 mb-5'>{t('navStudies')}</p>
      </div>
    </div> 
    <div className='container-md w-90 mx-auto pb-5'>
      <p data-aos="fade-up" data-aos-delay="400" className='pt-5 mb-4 fs-4 text-main2 fw-bold position-relative w-fit line3 text-center text-justify'>{t('studies.text1')}</p> 
      <p data-aos="fade-up" data-aos-delay="700" className="py-3 lh-lg fs-5 text-justify">{t('studies.text2')}</p>
      <ul data-aos="fade-right" data-aos-delay="1000" className={`list-style-auto fs-5 fw-bold px-3`}>
        <li data-aos="fade-right" data-aos-delay="200" className="py-3 text-justify">{t('studies.1')}</li>
        <li data-aos="fade-right" data-aos-delay="400" className="py-3 text-justify">{t('studies.2')}</li>
        <div data-aos="zoom-in" data-aos-delay="600">
          <Slider
            autoplay= {2}
            infinite={false}
            arrows={false}
            dots={false}
            speed={1300}
            autoplaySpeed= {1300}
            initialSlide= {0}
            slidesToShow={2}
            slidesToScroll={1}
            className='my-3 cursor-grab'
            responsive={[
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  arrows: true,
                  dots: true,
                  infinite: true,
                },
              },
            ]}
          >
            <div className='portrait2 overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={second1} alt={t('studies.2')} />
            </div>
            <div className='portrait2 overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={second2} alt={t('studies.2')} />
            </div>
          </Slider>
        </div>  
        <li data-aos="fade-right" data-aos-delay="600" className="py-3 text-justify">{t('studies.3')}</li>
        <li data-aos="fade-right" data-aos-delay="800" className="py-3 text-justify">{t('studies.4')}</li>
        <div data-aos="zoom-in" data-aos-delay="800">
          <Slider
          autoplay= {2}
          infinite={false}
          arrows={false}
          dots={false}
          speed={1300}
          autoplaySpeed= {1300}
          initialSlide= {0}
          slidesToShow={2}
          slidesToScroll={1}
          className='my-3 cursor-grab'
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                arrows: true,
                dots: true,
                infinite: true,
              },
            },
          ]}
        >
          <div className='portrait2 overflow-hidden px-lg-4 px-md-3 px-2'>
            <img loading='lazy' className='h-100 border w-100' src={fourth1} alt={t('studies.2')} />
          </div>
          <div className='portrait2 overflow-hidden px-lg-4 px-md-3 px-2'>
            <img loading='lazy' className='h-100 border w-100' src={fourth2} alt={t('studies.2')} />
          </div>
          </Slider>
        </div>
        <li data-aos="fade-right" data-aos-delay="1000" className="py-3 text-justify">{t('studies.5')}</li>
        <div data-aos="zoom-in" data-aos-delay="1000">
          <Slider
            autoplay= {3}
            infinite={false}
            arrows={false}
            dots={false}
            speed={1300}
            autoplaySpeed= {1300}
            initialSlide= {0}
            slidesToShow={3}
            slidesToScroll={1}
            className='my-3 cursor-grab'
            responsive={[
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  arrows: true,
                  dots: true,
                  infinite: true,
                },
              },
            ]}
          >
            <div className='portrait2 overflow-hidden px-lg-4 px-md-3 px-2'>
              <img data-aos="zoom-in" data-aos-delay="1000" loading='lazy' className='h-100 border w-100' src={fifth1} alt={t('studies.2')} />
            </div>
            <div className='portrait2 overflow-hidden px-lg-4 px-md-3 px-2'>
              <img data-aos="zoom-in" data-aos-delay="1400" loading='lazy' className='h-100 border w-100' src={fifth2} alt={t('studies.2')} />
            </div>
            <div className='portrait2 overflow-hidden px-lg-4 px-md-3 px-2'>
              <img data-aos="zoom-in" data-aos-delay="1600" loading='lazy' className='h-100 border w-100' src={fifth3} alt={t('studies.2')} />
            </div>
          </Slider>
        </div>
        <li data-aos="fade-right" data-aos-delay="1200" className="py-3 text-justify">{t('studies.6')}</li>
        <div data-aos="zoom-in" data-aos-delay="1200">
          <Slider
            autoplay= {2}
            infinite={false}
            arrows={false}
            dots={false}
            speed={1300}
            autoplaySpeed= {1300}
            initialSlide= {0}
            slidesToShow={2}
            slidesToScroll={1}
            className='my-3 cursor-grab'
            responsive={[
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  arrows: true,
                  dots: true,
                  infinite: true,
                },
              },
            ]}
          >
            <div data-aos="zoom-in" data-aos-delay="1400" className='portrait2 overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={sixth1} alt={t('studies.2')} />
            </div>
            <div data-aos="zoom-in" data-aos-delay="1600" className='portrait2 overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={sixth2} alt={t('studies.2')} />
            </div>
          </Slider>
        </div>
        <li data-aos="fade-right" data-aos-delay="1600" className="py-3 text-justify">{t('studies.7')}</li>
        <div data-aos="zoom-in" data-aos-delay="1600">
          <Slider
            autoplay= {3}
            infinite={false}
            arrows={false}
            dots={false}
            speed={1300}
            autoplaySpeed= {1300}
            initialSlide= {0}
            slidesToShow={3}
            slidesToScroll={1}
            className='my-3 cursor-grab'
            responsive={[
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  arrows: true,
                  dots: true,
                  infinite: true,
                },
              },
            ]}
          >
            <div data-aos="zoom-in" data-aos-delay="1800" className='portrait overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={seventh1} alt={t('studies.2')} />
            </div>
            <div data-aos="zoom-in" data-aos-delay="2000" className='portrait overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={seventh2} alt={t('studies.2')} />
            </div>
            <div data-aos="zoom-in" data-aos-delay="2100" className='portrait overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={seventh3} alt={t('studies.2')} />
            </div>
          </Slider>
        </div>
        <li data-aos="fade-right" data-aos-delay="1200" className="py-3 text-justify">{t('studies.8')}</li>
        <div data-aos="zoom-in" data-aos-delay="1200">
          <Slider
            autoplay= {3}
            infinite={false}
            arrows={false}
            dots={false}
            speed={1300}
            autoplaySpeed= {1300}
            initialSlide= {0}
            slidesToShow={3}
            slidesToScroll={1}
            className='my-3 cursor-grab'
            responsive={[
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  arrows: true,
                  dots: true,
                  infinite: true,
                },
              },
            ]}
          >
            <div data-aos="zoom-in" data-aos-delay="1200" className='portrait overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={eightth1} alt={t('studies.2')} />
            </div>
            <div data-aos="zoom-in" data-aos-delay="1400" className='portrait overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={eightth2} alt={t('studies.2')} />
            </div>
            <div data-aos="zoom-in" data-aos-delay="1600" className='portrait overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={eightth3} alt={t('studies.2')} />
            </div>
          </Slider>
        </div>
        <p data-aos="fade-up" data-aos-delay="1800" className="lh-lg fw-normal fs-5 pt-3">{t('studies.text3')}</p>
        <li data-aos="fade-right" data-aos-delay="1900" className="py-3 text-justify">{t('studies.9')}</li>
        <div data-aos="zoom-in" data-aos-delay="1900">
          <Slider
            autoplay={true}
            infinite={true}
            dots
            speed={1300}
            autoplaySpeed= {1300}
            initialSlide= {0}
            slidesToShow={4}
            slidesToScroll={1}
            className='my-3 cursor-grab'
            responsive={[
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                },
              },
            ]}
          >
            <div data-aos="fade-up" data-aos-delay="1100" className='portrait overflow-hidden px-2'>
              <img loading='lazy' className='h-100 border w-100' src={ninth1} alt={t('studies.2')} />
            </div>
            <div data-aos="fade-up" data-aos-delay="1200" className='portrait overflow-hidden px-2'>
              <img loading='lazy' className='h-100 border w-100' src={ninth2} alt={t('studies.2')} />
            </div>
            <div data-aos="fade-up" data-aos-delay="1300" className='portrait overflow-hidden px-2'>
              <img loading='lazy' className='h-100 border w-100' src={ninth3} alt={t('studies.2')} />
            </div>
            <div data-aos="fade-up" data-aos-delay="1400" className='portrait overflow-hidden px-2'>
              <img loading='lazy' className='h-100 border w-100' src={ninth4} alt={t('studies.2')} />
            </div>
            <div data-aos="fade-up" data-aos-delay="1500" className='portrait overflow-hidden px-2'>
              <img loading='lazy' className='h-100 border w-100' src={ninth5} alt={t('studies.2')} />
            </div>
            <div data-aos="fade-up" data-aos-delay="1600" className='portrait overflow-hidden px-2'>
              <img loading='lazy' className='h-100 border w-100' src={ninth6} alt={t('studies.2')} />
            </div>
          </Slider>
        </div>
        <li data-aos="fade-right" data-aos-delay="1200" className="py-3 text-justify">{t('studies.10')}</li>
        <div data-aos="zoom-in" data-aos-delay="1200">
          <Slider
            autoplay= {3}
            infinite={false}
            arrows={false}
            dots={false}
            speed={1300}
            autoplaySpeed= {1300}
            initialSlide= {0}
            slidesToShow={3}
            slidesToScroll={1}
            className='my-3 cursor-grab'
            responsive={[
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  arrows: true,
                  dots: true,
                  infinite: true,
                },
              },
            ]}
          >
            <div data-aos="zoom-in" data-aos-delay="1200" className='portrait overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={tenth1} alt={t('studies.2')} />
            </div>
            <div data-aos="zoom-in" data-aos-delay="1400" className='portrait overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={tenth2} alt={t('studies.2')} />
            </div>
            <div data-aos="zoom-in" data-aos-delay="1600" className='portrait overflow-hidden px-lg-4 px-md-3 px-2'>
              <img loading='lazy' className='h-100 border w-100' src={tenth3} alt={t('studies.2')} />
            </div>
          </Slider>
        </div>
      </ul>
    </div>
  </div>
  </>
}
